import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/table-configuration.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { TablesService } from '../../services';

export const LoadTableConfiguration$ = createEffect(
  (actions$ = inject(Actions), service = inject(TablesService)) => {
    return actions$.pipe(
      ofType(actions.LoadTableConfiguration),
      switchMap(({ name }) =>
        service.getTableConfiguration(name).pipe(
          map(configuration => actions.LoadTableConfigurationSuccess({ name, configuration })),
          catchError(error => of(actions.LoadTableConfigurationFail({ name, error })))
        )
      )
    );
  },
  { functional: true }
);
