import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromConfig from '../reducers/table-configuration.reducer';
import * as fromRoot from '../../../store';
import { TableConfiguration } from '../../models';

export const getTableConfigState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.configurations);

export const getTableConfigurationEntities = createSelector(getTableConfigState, fromConfig.getTableConfigurationEntities);
export const getTableConfigurationLoaded = createSelector(getTableConfigState, fromConfig.getTableConfigurationLoaded);
export const getTableConfigurationLoading = createSelector(getTableConfigState, fromConfig.getTableConfigurationLoading);

export const getSelectedTableConfiguration = createSelector(getTableConfigurationEntities, fromRoot.getRouterState, (entities, router) => {
  return router.state && entities[router.state.params?.['table'] || router.state.data?.['table']];
});

export const getSelectedTableConfigurationLoading = createSelector(
  getTableConfigurationLoading,
  fromRoot.getRouterState,
  (loading, router) => {
    return router.state && loading.includes(router.state.params?.['table'] || router.state.data?.['table']);
  }
);

export const getSelectedTableConfigurationLoaded = createSelector(
  getTableConfigurationLoaded,
  fromRoot.getRouterState,
  (loaded, router) => {
    return router.state && loaded.includes(router.state.params?.['table'] || router.state.data?.['table']);
  }
);
