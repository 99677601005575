// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { TableConfiguration } from '../../models';

export const LoadTableConfiguration = createAction('[Table Config] Load Table Configuration', props<{ name: string }>());
export const LoadTableConfigurationSuccess = createAction(
  '[Table Config] Load Table Configuration Success',
  props<{ name: string; configuration: TableConfiguration }>()
);
export const LoadTableConfigurationFail = createAction(
  '[Table Config] Load Table Configuration Fail',
  props<{ name: string; error: any }>()
);
