import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/record-detail.action';
import { SqlRecord } from '../../models';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface RecordDetailState {
  record: SqlRecord | null;
  table: string | null;
  loading: number | string | null;
  loaded: number | string | null;
  error?: any;
}

export const initialState: RecordDetailState = {
  record: null,
  table: null,
  loaded: null,
  loading: null,
};

export const RecordDetailReducer = createReducer(
  initialState,
  on(actions.LoadRecordDetail, (state: RecordDetailState, { name, id }) => {
    return {
      ...state,
      record: null,
      table: name,
      loading: id,
      loaded: null,
    };
  }),
  on(actions.LoadRecordDetailSuccess, (state: RecordDetailState, { name, id, record }) => {
    return {
      ...state,
      record,
      loading: null,
      loaded: id,
      table: name,
    };
  }),
  on(actions.LoadRecordDetailFail, (state: RecordDetailState, { name, error }) => {
    return {
      ...state,
      loading: null,
      loaded: null,
      record: null,
      table: null,
      error,
    };
  }),
  on(actions.ResetRecordDetail, (state: RecordDetailState) => {
    return {
      ...initialState,
    };
  })
);

export const getRecordDetail = (state: RecordDetailState) => state.record;
export const getRecordDetailTable = (state: RecordDetailState) => state.table;
export const getRecordDetailLoading = (state: RecordDetailState) => state.loading;
export const getRecordDetailLoaded = (state: RecordDetailState) => state.loaded;
