// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { SqlRecord } from '../../models';

// Load overview records
export const LoadRecords = createAction('[Records] Load Records by Tablename', props<{ name: string }>());
export const LoadRecordsSuccess = createAction(
  '[Records] Load Records By Tablename Success',
  props<{ name: string; records: SqlRecord[] }>()
);
export const LoadRecordsFail = createAction('[Records] Load Records by Tablename Fail', props<{ name: string; error: any }>());
