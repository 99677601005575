import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromRecords from '../reducers/records.reducer';
import * as fromRoot from '../../../store';
import { SqlRecord } from '../../models';

export const getRecordsState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.records);

export const getRecordsEntities = createSelector(getRecordsState, fromRecords.getRecordsEntities);
export const getRecordsLoaded = createSelector(getRecordsState, fromRecords.getRecordsLoaded);
export const getRecordsLoading = createSelector(getRecordsState, fromRecords.getRecordsLoading);

export const getAllRecords = createSelector(getRecordsEntities, (entities): SqlRecord[] => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getSelectedRecordsLoading = createSelector(getRecordsLoading, fromRoot.getRouterState, (loading, router): boolean => {
  return router.state && loading === (router.state.params?.['table'] || router.state.data?.['table']);
});

export const getSelectedRecordsLoaded = createSelector(getRecordsLoaded, fromRoot.getRouterState, (loaded, router): boolean => {
  return router.state && loaded == (router.state.params?.['table'] || router.state.data?.['table']);
});
