import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/table-preferences.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { TablesService } from '../../services';

export const LoadTablePreferences$ = createEffect(
  (actions$ = inject(Actions), service = inject(TablesService)) => {
    return actions$.pipe(
      ofType(actions.LoadTablePreferences),
      switchMap(({ name }) =>
        service.getTablePreferences(name).pipe(
          map(preferences => actions.LoadTablePreferencesSuccess({ name, preferences })),
          catchError(error => of(actions.LoadTablePreferencesFail({ name, error })))
        )
      )
    );
  },
  { functional: true }
);

export const UpdateTablePreferences$ = createEffect(
  (actions$ = inject(Actions), service = inject(TablesService)) => {
    return actions$.pipe(
      ofType(actions.UpdateTablePreferences),
      switchMap(({ name, preferences }) =>
        service.updateTablePreferences(name, preferences).pipe(
          map(preferences => actions.UpdateTablePreferencesSuccess({ name, preferences })),
          catchError(error => of(actions.UpdateTablePreferencesFail({ name, error })))
        )
      )
    );
  },
  { functional: true }
);
