import { createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/table-preferences.action';
import { TablePreferences } from '../../models';

export interface TablePreferencesState {
  entities: { [name: string]: TablePreferences | null };
  loading: string[];
  loaded: string[];
  error?: any;
}

export const initialState: TablePreferencesState = {
  entities: {},
  loaded: [],
  loading: [],
};

export const TablePreferencesReducer = createReducer(
  initialState,
  on(UserActions.LoadTablePreferences, UserActions.UpdateTablePreferences, (state: TablePreferencesState, { name }) => {
    return {
      ...state,
      loading: [...state.loading, name],
      loaded: state.loaded.filter(n => n !== name),
    };
  }),
  on(
    UserActions.LoadTablePreferencesSuccess,
    UserActions.UpdateTablePreferencesSuccess,
    (state: TablePreferencesState, { name, preferences }) => {
      const entities = { ...state.entities, [name]: preferences };

      return {
        ...state,
        entities,
        loading: state.loading.filter(n => n !== name),
        loaded: [...state.loaded, name],
      };
    }
  ),
  on(UserActions.LoadTablePreferencesFail, UserActions.UpdateTablePreferencesFail, (state: TablePreferencesState, { name, error }) => {
    return {
      ...state,
      loading: state.loading.filter(n => n !== name),
      loaded: state.loaded.filter(n => n !== name),
      error,
    };
  })
);

export const getTablePreferencesEntities = (state: TablePreferencesState) => state.entities;
export const getTablePreferencesLoading = (state: TablePreferencesState) => state.loading;
export const getTablePreferencesLoaded = (state: TablePreferencesState) => state.loaded;
