import * as tableEffects from './tables.effect';
import * as tablePreferencesEffects from './table-preferences.effect';
import * as tableConfigurationEffects from './table-configuration.effect';
import * as recordEffects from './records.effect';
import * as recordDetailsEffects from './record-detail.effect';

export const effects = {
  ...tableEffects,
  ...recordEffects,
  ...tablePreferencesEffects,
  ...tableConfigurationEffects,
  ...recordDetailsEffects,
};
