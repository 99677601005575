// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { TablePreferences } from '../../models';

// Load Preferences
export const LoadTablePreferences = createAction('[Table Prefs] Load Table Preferences', props<{ name: string }>());
export const LoadTablePreferencesSuccess = createAction(
  '[Table Prefs] Load Table Preferences Success',
  props<{ name: string; preferences: TablePreferences }>()
);
export const LoadTablePreferencesFail = createAction('[Table Prefs] Load Table Preferences Fail', props<{ name: string; error: any }>());

// Update Preferences
export const UpdateTablePreferences = createAction(
  '[Table Prefs] Update Table Preferences',
  props<{ name: string; preferences: TablePreferences }>()
);
export const UpdateTablePreferencesSuccess = createAction(
  '[Table Prefs] Update Table Preferences Success',
  props<{ name: string; preferences: TablePreferences }>()
);
export const UpdateTablePreferencesFail = createAction(
  '[Table Prefs] Update Table Preferences Fail',
  props<{ name: string; error: any }>()
);
