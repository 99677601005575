// tslint:disable:max-line-length
import { createAction, props } from '@ngrx/store';
import { SqlRecord } from '../../models';

// Load record by Id
export const LoadRecordDetail = createAction('[Records] Load Record  Detail by Id', props<{ name: string; id: number | string }>());
export const LoadRecordDetailSuccess = createAction(
  '[Records] Load Record  Detail By Id Success',
  props<{ name: string; id: string | number; record: SqlRecord }>()
);
export const LoadRecordDetailFail = createAction('[Records] Load Record  Detail by Id Fail', props<{ name: string; error: any }>());

export const ResetRecordDetail = createAction('[Records] Reset Record Detail');
