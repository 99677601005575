import * as fromTables from './tables.reducer';
import * as fromRecords from './records.reducer';
import * as fromDetail from './record-detail.reducer';
import * as fromPrefs from './table-preferences.reducer';
import * as fromConfig from './table-configuration.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'Content';

export interface ContentState {
  tables: fromTables.TablesState;
  records: fromRecords.RecordsState;
  preferences: fromPrefs.TablePreferencesState;
  configurations: fromConfig.TableConfigurationState;
  recordDetail: fromDetail.RecordDetailState;
}

export const reducers: ActionReducerMap<ContentState> = {
  tables: fromTables.TablesReducer,
  records: fromRecords.RecordsReducer,
  preferences: fromPrefs.TablePreferencesReducer,
  configurations: fromConfig.TableConfigurationReducer,
  recordDetail: fromDetail.RecordDetailReducer,
};

export const getContentState = createFeatureSelector<ContentState>(featureKey);
export const metaReducers: MetaReducer<ContentState>[] = !environment.production ? [] : [];
