import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromPrefs from '../reducers/table-preferences.reducer';
import * as fromRoot from '../../../store';
import { TablePreferences } from '../../models';

export const getTablePrefsState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.preferences);

export const getTablePreferencesEntities = createSelector(getTablePrefsState, fromPrefs.getTablePreferencesEntities);
export const getTablePreferencesLoaded = createSelector(getTablePrefsState, fromPrefs.getTablePreferencesLoaded);
export const getTablePreferencesLoading = createSelector(getTablePrefsState, fromPrefs.getTablePreferencesLoading);

export const getSelectedTablePreferences = createSelector(getTablePreferencesEntities, fromRoot.getRouterState, (entities, router) => {
  return router.state && entities[router.state.params?.['table'] || router.state.data?.['table']];
});

export const getSelectedTablePreferencesLoading = createSelector(getTablePreferencesLoading, fromRoot.getRouterState, (loading, router) => {
  return router.state && loading.includes(router.state.params?.['table'] || router.state.data?.['table']);
});

export const getSelectedTablePreferencesLoaded = createSelector(getTablePreferencesLoaded, fromRoot.getRouterState, (loaded, router) => {
  return router.state && loaded.includes(router.state.params?.['table'] || router.state.data?.['table']);
});
