import { createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/tables.action';
import { SqlTable } from '../../models';

export interface TablesState {
  entities: { [name: string]: SqlTable };
  loading: string[];
  loaded: string[];
  error?: any;
}

export const initialState: TablesState = {
  entities: {},
  loaded: [],
  loading: [],
};

export const TablesReducer = createReducer(
  initialState,
  on(UserActions.LoadTable, (state: TablesState, { name }) => {
    return {
      ...state,
      loading: [...state.loading, name],
      loaded: state.loaded.filter(n => n !== name),
    };
  }),
  on(UserActions.LoadTableSuccess, (state: TablesState, { table }) => {
    const entities = { ...state.entities, [table.name]: table };

    return {
      ...state,
      entities,
      loading: state.loading.filter(n => n !== table.name),
      loaded: [...state.loaded, table.name],
    };
  }),
  on(UserActions.LoadTableFail, (state: TablesState, { name, error }) => {
    return {
      ...state,
      loading: state.loading.filter(n => n !== name),
      loaded: state.loaded.filter(n => n !== name),
      error,
    };
  })
);

export const getTablesEntities = (state: TablesState) => state.entities;
export const getTablesLoading = (state: TablesState) => state.loading;
export const getTablesLoaded = (state: TablesState) => state.loaded;
