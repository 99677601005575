import { createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/table-configuration.action';
import { TableConfiguration } from '../../models';

export interface TableConfigurationState {
  entities: { [name: string]: TableConfiguration | null };
  loading: string[];
  loaded: string[];
  error?: any;
}

export const initialState: TableConfigurationState = {
  entities: {},
  loaded: [],
  loading: [],
};

export const TableConfigurationReducer = createReducer(
  initialState,
  on(UserActions.LoadTableConfiguration, (state: TableConfigurationState, { name }) => {
    return {
      ...state,
      loading: [...state.loading, name],
      loaded: state.loaded.filter(n => n !== name),
    };
  }),
  on(UserActions.LoadTableConfigurationSuccess, (state: TableConfigurationState, { name, configuration }) => {
    const entities = { ...state.entities, [name]: configuration };

    return {
      ...state,
      entities,
      loading: state.loading.filter(n => n !== name),
      loaded: [...state.loaded, name],
    };
  }),
  on(UserActions.LoadTableConfigurationFail, (state: TableConfigurationState, { name, error }) => {
    return {
      ...state,
      loading: state.loading.filter(n => n !== name),
      loaded: state.loaded.filter(n => n !== name),
      error,
    };
  })
);

export const getTableConfigurationEntities = (state: TableConfigurationState) => state.entities;
export const getTableConfigurationLoading = (state: TableConfigurationState) => state.loading;
export const getTableConfigurationLoaded = (state: TableConfigurationState) => state.loaded;
