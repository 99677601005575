import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/record-detail.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { TablesService } from '../../services';

export const LoadRecord$ = createEffect(
  (actions$ = inject(Actions), service = inject(TablesService)) => {
    return actions$.pipe(
      ofType(actions.LoadRecordDetail),
      switchMap(({ name, id }) =>
        service.getRecord(name, id).pipe(
          map(record => actions.LoadRecordDetailSuccess({ name, id, record })),
          catchError(error => of(actions.LoadRecordDetailFail({ name, error })))
        )
      )
    );
  },
  { functional: true }
);
