import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromTables from '../reducers/tables.reducer';
import * as fromRoot from '../../../store';
import { SqlTable } from '../../models';

export const getTablesState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.tables);

export const getTablesEntities = createSelector(getTablesState, fromTables.getTablesEntities);
export const getTablesLoaded = createSelector(getTablesState, fromTables.getTablesLoaded);
export const getTablesLoading = createSelector(getTablesState, fromTables.getTablesLoading);

export const getSelectedTable = createSelector(getTablesEntities, fromRoot.getRouterState, (entities, router): SqlTable | undefined => {
  return router.state && entities[router.state.params?.['table'] || router.state.data?.['table']];
});

export const getSelectedTableLoading = createSelector(getTablesLoading, fromRoot.getRouterState, (loading, router): boolean => {
  return router.state && loading.includes(router.state.params?.['table'] || router.state.data?.['table']);
});

export const getSelectedTableLoaded = createSelector(getTablesLoaded, fromRoot.getRouterState, (loaded, router): boolean => {
  return router.state && loaded.includes(router.state.params?.['table'] || router.state.data?.['table']);
});
