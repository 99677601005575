import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SqlRecord, SqlTable, TableConfiguration, TablePreferences } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TablesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getTable(name: string) {
    return this.http.get<SqlTable>(`${this.BASE_URL}api/tables/${name}`);
  }

  getTablePreferences(name: string) {
    return this.http.get<TablePreferences>(`${this.BASE_URL}api/tables/${name}/preferences`);
  }

  updateTablePreferences(name: string, preferences: TablePreferences) {
    return this.http.post<TablePreferences>(`${this.BASE_URL}api/tables/${name}/preferences`, { preferences: JSON.stringify(preferences) });
  }

  getTableConfiguration(name: string) {
    return this.http.get<TableConfiguration>(`${this.BASE_URL}api/tables/${name}/configuration`);
  }

  getRecords(name: string) {
    return this.http.get<SqlRecord[]>(`${this.BASE_URL}api/tables/${name}/records`);
  }

  getRecord(name: string, id: number | string) {
    return this.http.get<SqlRecord>(`${this.BASE_URL}api/tables/${name}/records/${id}`);
  }
}
