import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/tables.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { TablesService } from '../../services';

export const LoadTable$ = createEffect(
  (actions$ = inject(Actions), service = inject(TablesService)) => {
    return actions$.pipe(
      ofType(actions.LoadTable),
      switchMap(({ name }) =>
        service.getTable(name).pipe(
          map(table => actions.LoadTableSuccess({ table })),
          catchError(error => of(actions.LoadTableFail({ name, error })))
        )
      )
    );
  },
  { functional: true }
);
